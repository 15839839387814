import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useInterval } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import {
	RouterTransition,
	AppWrapper,
	AgeScreener,
	CanvasProvider,
	Warnings
} from 'bat-components';

import { Navbar } from 'components/molecules';
import {
	Home,
	Learn,
	Compare,
	Flavours,
	SharedProductDetail,
	SharedProducts,
	Quiz,
	QuizResult,
	WhereToBuy,
	WhatsIn,
	About,
	Know,
	SignUpScreen,
	Ad
} from 'components/pages';

import style from './app.module.scss';
import { Pick } from 'components/pages/pick/Pick';

export default function Main() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [newUserSession, setNewUserSession] = useState(false);

	const { homePage, quiz, warnings, country } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';
		return {
			homePage: state.content.getIn(['content', 'pages', 'age_screener', 'next_page']) || '/home',
			quiz: state.content.getIn(['content', 'pages', 'quiz']) || undefined,
			warnings: state.content.getIn(['content', `warnings${language}`])?.toJS(),
			country: state.content.getIn(['content', 'market_details', 'country'])
		};
	});

	useInterval(() => {
		setNewUserSession(!newUserSession);
	}, 90000);

	useEffect(() => {
		if (quiz !== undefined) {
			dispatch.content.setProductsforQuiz();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const actionCustomFunc = () => {
		if (country !== 'uae_gtr' && country !== 'de_gtr') {
			history.push('/signup');
		}
	};

	return (
		<CanvasProvider>
			<AppWrapper hotCornerAction={{ code: '222', action: actionCustomFunc }}>
				<>
					<Route
						path="/(learn|glo|velo|vuse|where-to-buy|whats-in|about|about-pick|signup|frankfurt)"
						children={({ match, history }) => <Navbar show={!!match} history={history} />}
					/>

					<div className={style.scrollWrapper}>
						<RouterTransition>
							<Route path="/home" component={Home} />

							<Route path="/signup" component={SignUpScreen} />

							<Route exact path="/learn" component={Learn} />
							<Route path="/learn/compare" component={Compare} />
							<Route exact path="/learn/quiz" component={Quiz} />
							<Route path="/learn/quiz/results" component={QuizResult} />

							<Route exact path="/:brand" component={SharedProducts} />
							<Route exact path="/:brand/product" component={SharedProductDetail} />
							<Route path="/:brand/product/:product" component={Flavours} />
							<Route path="/:brand/ad/:product" component={Ad} />

							<Route exact path="/where-to-buy/:brand" component={WhereToBuy} />

							<Route exact path="/whats-in/:brand" component={WhatsIn} />
							<Route exact path="/about/:brand" component={About} />
							<Route exact path="/about-pick/:brand" component={Pick} />
							<Route exact path="/frankfurt/:brand" component={Know} />
							<Route path="/frankfurt/:brand" component={Know} />

							<Route path="/" exact render={() => <AgeScreener next={homePage} />} />
						</RouterTransition>
					</div>

					<Route
						path="/frankfurt/:brand"
						children={() => <Warnings key="warning-with-modal" content={warnings} />}
					/>

					<Warnings key="warning-no-modal" content={warnings} ignoreModal={true} />
				</>
			</AppWrapper>
		</CanvasProvider>
	);
}
