import React from 'react';
import clsx from 'clsx';

import { useHistory } from 'react-router-dom';

import { Button } from 'bat-components';
import style from './back-button.module.scss';

export const BackButton = ({
	brand = '',
	page = '',
	variant = 'customisedToProduct',
	size = null,
	children
}) => {
	const history = useHistory();

	return (
		<Button
			className={clsx(style[variant], style.backButton)}
			variant={variant}
			size={size}
			onClick={() => history.goBack()}
			analytics={{
				category: `${brand} - ${page} page`,
				action: `Back button clicked`,
				label: `User is redirected to previous page in history`
			}}
		>
			{children}
		</Button>
	);
};
