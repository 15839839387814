import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBackground, useDisclaimer, useTheme } from 'bat-components';
import { TitleWithStrapline } from 'components/templates';

export const WhatsIn = () => {
	const { params } = useRouteMatch();

	useDisclaimer(params.brand);

	const { data } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			data: state.content.getIn(['content', `pages${language}`, 'whats_in', params.brand])?.toJS()
		};
	});

	useBackground(data?.background);
	useTheme(params.brand);

	if (!data) return <></>;

	return <TitleWithStrapline data={data} page="What's in" />;
};
