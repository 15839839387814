import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { motion, AnimatePresence } from 'framer-motion';
import { animationProductImage } from './animation';

import style from './center-column.module.scss';
import { ColoursBlock } from '../colours-block/ColoursBlock';

export const CenterColumn = ({
	currentProductImg,
	currentProductColours,
	currentProductData,
	page,
	title,
	className,
	layout = 'default',
	colour_text,
	activeProd,
	locale
}) => {
	const additionalClass = currentProductColours !== undefined ? style.productAltLayout : null;

	/** Layout configs */
	const layoutDisplay = {
		reduced: { icons: true, copy: false, colourBlock: false },
		default: { icons: false, copy: true, colourBlock: true }
	};

	const [natWidth, setNatWidth] = useState(0);

	const pImg = new Image();
	pImg.src = currentProductImg;

	pImg.onload = function () {
		setNatWidth(this.naturalWidth);
	};

	console.log(layoutDisplay[layout].colourBlock);
	console.log(currentProductColours);

	return (
		<div
			data-layout={layout}
			className={clsx(style.product, style[page], additionalClass, className)}
		>
			<AnimatePresence>
				<motion.img
					style={{
						maxWidth: locale === 'za' ? natWidth : 'auto'
					}}
					src={currentProductImg}
					className={clsx(style.productImage, style[page])}
					alt={title}
					key={currentProductData?.text_under_image || currentProductImg}
					initial="from"
					animate="to"
					exit="exit"
					variants={animationProductImage}
				/>
				{currentProductData?.text_under_image && (
					<span className={style.textUnder}>{currentProductData.text_under_image}</span>
				)}
				{layoutDisplay[layout].colourBlock && currentProductColours && Object.keys(currentProductColours).length > 1 && (
					<ColoursBlock
						currentProductColours={currentProductColours}
						productName={currentProductData && currentProductData.shortName}
						page={page}
						activeProd={activeProd}
						locale={locale}
						column="center"
					/>
				)}
			</AnimatePresence>
		</div>
	);
};

CenterColumn.propTypes = {
	currentProductImg: PropTypes.string,
	page: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string
};
