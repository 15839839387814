import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBackground, useTheme, useDisclaimer } from 'bat-components';
import { TitleWithStrapline } from 'components/templates';

import style from './about.module.scss';

export const About = () => {
	const { params } = useRouteMatch();

	const { data } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			data: state.content.getIn(['content', `pages${language}`, 'about', params.brand])?.toJS()
		};
	});

	useBackground(data?.background);
	useTheme(params.brand);
	useDisclaimer(params.brand);

	if (!data) return <></>;

	return (
		<TitleWithStrapline data={data} page="How to use">
			<div className={style.iconsContainer}>
				{data.icons.map(icon => {
					return (
						<div className={style.iconWrapper} key={icon.icon}>
							<img className={style.icon} src={icon.icon} alt="" />
							<span className={style.title}>{icon.title}</span>
							<span className={style.copy}>{icon.copy}</span>
						</div>
					);
				})}
			</div>
		</TitleWithStrapline>
	);
};
