import React from 'react';
import { Layout, useBackground, Button, useDisclaimer } from 'bat-components';
import { changeText } from 'helpers';
import { useDispatch } from 'react-redux';

import style from './quiz-result.module.scss';

export const QuizResult = ({ results, background, title, btn_text, getName }) => {
	const dispatch = useDispatch();
	useDisclaimer('default');
	useBackground(background);

	function setActiveProduct(e, id, type) {
		const product = { [type]: { product: id, colour: 'default', colourName: 'default' } };

		dispatch.content.selectActiveProduct(product);
	}

	return (
		<Layout>
			<div className={style.wrapper}>
				<div className={style.title}>{title}</div>
				<div className={style.columns}>
					{results.map((result, i) => {
						if (result) {
							const name = result.title;

							return (
								<Button
									className={style.column}
									key={`${i}-column`}
									to={`/${result.type}/product`}
									onClick={e => setActiveProduct(e, result.id, result.type)}
									variants={{
										tap: {
											scale: 1.02
										}
									}}
									analytics={{
										category: 'Quiz results page',
										action: `${name} button clicked`,
										label: `User is redirected to ${result.type}`
									}}
									variant="img"
								>
									<img src={result.image} alt={result.product} />
									<div className={style.name}>
										<span
											className={(style[result.type], name && style[name.toLowerCase()])}
											dangerouslySetInnerHTML={{
												__html: changeText(name, 'trademarkSmallFont')
											}}
										/>
									</div>
									<Button variant="primary" className="btnText">
										{`${btn_text} ${result.type}`}
									</Button>
								</Button>
							);
						}
						return null;
					})}
				</div>
			</div>
		</Layout>
	);
};
