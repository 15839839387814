import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useBackground, useTheme } from 'bat-components';
import { InfoColumn, Products as RightColumn } from 'components/molecules';

import style from './products.module.scss';

export const Products = ({ background, data, page, locale }) => {
	const { params } = useRouteMatch();

	useBackground(background);
	useTheme(params.brand);

	return (
		<div className={style.wrapper}>
			<InfoColumn
				data={data.info}
				locale={data.locale}
				logo={data.logo}
				className="productsInfo"
				page={page}
			/>
			<RightColumn
				productsToDisplay={data.products}
				className="products"
				page={page}
				locale={locale}
			/>
		</div>
	);
};
