import React from 'react';
import { useBackground, Layout } from 'bat-components';
import { useSelector } from 'react-redux';
import { SlantedBlocks } from 'components/molecules';

export const Home = () => {
	const selector = state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';
		console.log(language);

		return {
			sectionsText: state.content.getIn(['content', `pages${language}`, 'home', 'sections']),
			images: state.content.getIn(['content', `pages${language}`, 'home', 'background'])?.toJS()
		};
	};

	const { sectionsText, images } = useSelector(selector);

	useBackground(images);

	return (
		<Layout>
			<SlantedBlocks data={sectionsText} topPadding="noTopPadding" pageName="Home" />
		</Layout>
	);
};
