import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IndividualProduct } from 'components/templates';
import { useDisclaimer } from 'bat-components';
import useWrappedSelector from './SharedProductDetail.selector';

export const SharedProductDetail = () => {
	const { params } = useRouteMatch();

	const { background, firstTimeAnimation, split_button } = useWrappedSelector(params.brand);
	useDisclaimer(params.brand);

	return (
		<IndividualProduct
			background={background}
			productType={params.brand}
			split_button={split_button}
			firstTimeAnimation={firstTimeAnimation}
		/>
	);
};
