import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { sendAnalytics } from 'helpers';
import { motion } from 'framer-motion';
import { VideoPlayer, useModal } from 'bat-components';
import { ReactComponent as PlayIcon } from 'assets/icons/vid.svg';

import { changeText } from 'helpers';
import { firstGroup, secondGroup } from './animations';
import { Ads, Disclaimer } from './inner-components';

import style from './info-column.module.scss';

export const InfoColumn = ({ data, logo, className, page = '', rightWarning, copyrights }) => {
	let videosComponent = '';
	let noVideos = true;
	const [modalOpen, setModal] = useModal('video0');
	const [modalOpen1, setModal1] = useModal('video1');

	if (data.videos) {
		noVideos = false;

		videosComponent = data.videos.map((video, i) => {
			const analyticsPage = page;
			const analytics = {
				category: `${analyticsPage} Products`,
				action: `video${i} button clicked`,
				label: `User opened modal and watch ${video.text_2 || ''} video`
			};

			return (
				<div className={style.videoContainer} key={`${i}-video`}>
					<div
						className={style.video}
						onClick={() => {
							i === 1 ? setModal1(!modalOpen1) : setModal(!modalOpen);

							if (!modalOpen1) sendAnalytics(analytics);
						}}
					>
						<img src={video.thumbnail} alt="thumbnail" className={style[page]} />
						<PlayIcon className={style.playIcon} />
						{video.thumbnail_text && (
							<span className={style.thumbnailText}>{video.thumbnail_text}</span>
						)}
						<VideoPlayer
							isRightAlignCloseButton={true}
							src={video.video}
							name={`video${i}`}
							rightWarning={rightWarning}
							textColorOverride={video.text_color_override}
							copyrights={copyrights}
							page={page}
							videoModal
							backgroundColor={video.modal_background_color}
						/>
					</div>
				</div>
			);
		});
	}

	return (
		<motion.div
			className={clsx(style[className], 'columnWrapper', style[page], noVideos && style.noVideos)}
		>
			<motion.div className={style.firstGroup} variants={firstGroup} initial="from" animate="to">
				<img className={style.logo} src={logo} alt="Logo" />
			</motion.div>
			<motion.div className={style.secondGroup} variants={secondGroup} initial="from" animate="to">
				<h2 className={style[page]}>
					<span dangerouslySetInnerHTML={{ __html: changeText(data.title, 'trademark') }} />
				</h2>
				{data.small_title && (
					<h3 className={style[page]}>
						<span
							dangerouslySetInnerHTML={{
								__html: changeText(data.small_title, 'trademark')
							}}
						/>
					</h3>
				)}
				<p className={style[page]}>
					<span dangerouslySetInnerHTML={{ __html: changeText(data.copy, 'trademarkSmallFont') }} />
				</p>
				<div className={style.ctaWrapper}>
					<div className={style.videosContainer}>{videosComponent}</div>
					<Ads adsContent={data.ads} />
				</div>
			</motion.div>
			<Disclaimer text={data.disclaimer} />
		</motion.div>
	);
};

InfoColumn.propTypes = {
	data: PropTypes.object,
	logo: PropTypes.string,
	className: PropTypes.string,
	page: PropTypes.string
};
