import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBackground, useTheme, Layout, useDisclaimer, FlavourCarousel } from 'bat-components';
import { BackButton } from 'components/atoms';

import style from './flavours.module.scss';

export const Flavours = () => {
	const { params } = useRouteMatch();

	const { data } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			data: state.content
				.getIn(['content', `pages${language}`, `${params.brand}_flavours`, params.product])
				?.toJS()
		};
	});

	useBackground(data?.background);
	useTheme(params.brand);
	useDisclaimer(params.brand);

	if (!data) return <></>;

	return (
		<Layout className={style.flavoursWrapper}>
			<FlavourCarousel items={data.flavours} />
			{data.back_button && (
				<BackButton brand={params.brand} page="Flavours">
					{data.back_button}
				</BackButton>
			)}
		</Layout>
	);
};
