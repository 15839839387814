import { connect } from 'react-redux';

import { Navbar as Component } from './Navbar';

const mapState = (state, ownProps) => {
	const langState = state.content.getIn(['language']) || null;
	const language = langState && langState !== '' ? `_${langState}` : '';

	const background_1 =
		state.content.getIn(['content', 'pages', 'idle_screener', 'background'])?.toJS() || null;

	return {
		homePage:
			state.content.getIn(['content', `pages${language}`, 'age_screener', 'next_page']) || '/home',
		links: state.content.getIn(['content', `menu${language}`, 'links'])?.toJS() || null,
		btn_back_text: state.content.getIn(['content', `menu${language}`, 'back']),
		show: ownProps.show,

		history: ownProps.history,
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		idle_background: background_1 && background_1[0].content,
		multilanguage: state.content.getIn(['content', 'product', 'multilanguage']),
		langState
	};
};

export const Navbar = connect(mapState)(Component);
