import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { Button, Layout, useBackground } from 'bat-components';
import { title, options, option } from './animations';
import { useDispatch } from 'react-redux';

import style from './quiz.module.scss';


const selector = state => {
	const langState = state.content.getIn(['language']) || null;
	const language = langState && langState !== '' ? `_${langState}` : '';

	return {
		questions: state.content.getIn(['content', `quiz${language}`, 'questions']).toJS(),
		background: state.content.getIn(['content', `quiz${language}`, 'background'])?.toJS()
	};
};

export const Quiz = () => {
	const dispatch = useDispatch();
	const [resetCounter, setResetCounter] = useState(true);
	const [questionNumber, setQuestion] = useState(0);

	const { questions, background } = useSelector(selector);

	useBackground(background);

	useEffect(() => {
		if (resetCounter) {
			dispatch.content.resetCounters();
			setResetCounter(false);
		}
	}, [dispatch.content, resetCounter]);

	const selectAnswerAndGo = (product, dontUpdate) => {
		if (dontUpdate) {
			dispatch.content.getResultQuiz(product);
			setResetCounter(true);
		} else {
			dispatch.content.setPointsforQuiz(product);
			setQuestion(questionNumber + 1);
		}
	};

	const dontUpdate = Boolean(questionNumber === questions.length - 1);
	const propsButton = dontUpdate ? { to: '/learn/quiz/results' } : {};
	const currentQuestionData = questions[questionNumber];

	return (
		<Layout>
			<div className={style.wrapper} key={currentQuestionData.pretitle}>
				<motion.div className={style.headContainer} variants={title} initial="from" animate="to">
					<div className={style.pretitle}>{currentQuestionData.pretitle}</div>
					<div className={style.divider1} />
					<div className={style.mainText}>{currentQuestionData.text}</div>
				</motion.div>
				<motion.div
					className={style.questionsWrapper}
					variants={options}
					initial="from"
					animate="to"
				>
					{currentQuestionData.options.map((item, i) => {
						return (
							<Button
								key={`${i}-button`}
								className={style.imgBtn}
								variant="img"
								onClick={e =>
									selectAnswerAndGo(
										dontUpdate ? item.option_text : item.influencedProduct,
										dontUpdate
									)
								}
								analytics={{
									category: 'Quiz page',
									action: `Question ${questionNumber + 1}.${item.option_text} clicked`,
									label: 'User is answering quiz'
								}}
								{...propsButton}
							>
								<motion.div
									className={style.questionContainer}
									variants={option}
									key={`${i}-${currentQuestionData.pretitle}-question`}
								>
									<img src={item.image} alt={item.text} className={style.optionImage} />

									<div className={style.divider2} />
									<div className={style.text}>{item.text}</div>
									<div key={`${i}-buttonCircle`} className={style.btnOption}>
										{item.option_text}
									</div>
								</motion.div>
							</Button>
						);
					})}
				</motion.div>
			</div>
		</Layout>
	);
};

Quiz.propTypes = {
	questions: PropTypes.array,
	background: PropTypes.array
};
