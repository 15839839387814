import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Layout, useDisclaimer } from 'bat-components';
import { Products } from 'components/templates';
import useWrappedSelector from './SharedProducts.selector';

export const SharedProducts = () => {
	const { params } = useRouteMatch();
	const { data, background, page, locale } = useWrappedSelector(params.brand);

	useDisclaimer(params.brand);

	console.log('locale:' + locale);

	return (
		<Layout>
			<Products background={background} data={data} page={page} locale={locale} />
		</Layout>
	);
};
