import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBackground, Layout, useTheme, useDisclaimer } from 'bat-components';
import { BackButton } from 'components/atoms';

import style from './where-to-buy.module.scss';

export const WhereToBuy = () => {
	const { params } = useRouteMatch();

	const { data } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			data: state.content
				.getIn(['content', `pages${language}`, 'where_to_buy', params.brand])
				?.toJS()
		};
	});

	useBackground(data.background);
	useTheme(params.brand);
	useDisclaimer(params.brand);

	return (
		<Layout>
			<div className={style.wrapper}>
				<img className={style.logo} src={data.logo} alt="" />
				<div className={style.title}>{data.title}</div>
				<div className={style.strapline}>{data.strapline}</div>
				<div className={style.countriesWrapper}>
					{Object.entries(data.countries).map(([continentLabel, countries]) => {
						const countriesComponent = countries.map(country => {
							return (
								<div className={style.country} key={country.text}>
									<div className={style.flag} style={{ backgroundImage: `url(${country.icon})` }} />
									<span className={style.countryLabel}>{country.text}</span>
								</div>
							);
						});

						const minWidthTwoColumns =
							Object.keys(countries).length > 7
								? Object.keys(countries).length > 12
									? Object.keys(countries).length > 24
										? { minWidth: '950px' }
										: { minWidth: '750px' }
									: { minWidth: '500px' }
								: {};

						return (
							<div className={style.continent} key={continentLabel} style={minWidthTwoColumns}>
								<span className={style.continentLabel}>{continentLabel}</span>
								<div className={style.countries}>{countriesComponent}</div>
							</div>
						);
					})}
				</div>

				{data.back_button && (
					<BackButton brand={params.brand} page="Where to buy">
						{data.back_button}
					</BackButton>
				)}
			</div>
		</Layout>
	);
};
