import React from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useBackground, Layout, Button } from 'bat-components';
import clsx from 'clsx';

import style from './learn.module.scss';
import { containerVariants, blockVariants } from './learn.animation';
import { changeText } from 'helpers';
import buttonImg from 'assets/images/shared/button.png';

const selector = state => {
	const langState = state.content.getIn(['language']) || null;
	const language = langState && langState !== '' ? `_${langState}` : '';

	return {
		sectionsText: state.content.getIn(['content', `pages${language}`, 'learn', 'sections'])?.toJS(),
		images: state.content.getIn(['content', `pages${language}`, 'learn', 'background'])?.toJS()
	};
};

export const Learn = () => {
	const { images, sectionsText } = useSelector(selector);

	useBackground(images);

	return (
		<Layout>
			<motion.div
				className={clsx(style.contents)}
				initial="from"
				animate="to"
				variants={containerVariants}
				style={{ '--learn_cols': sectionsText.size }}
			>
				{sectionsText.map((item, i) => {
					return <Block key={clsx(i, item.title)} pageName="Learn" {...item} />;
				})}
			</motion.div>
		</Layout>
	);
};

const Block = ({ title, copy, link_to, logo, brand = '', pageName, background_img }) => {
	return (
		<motion.div
			className={style.block}
			variants={blockVariants}
			style={{ backgroundImage: `url(${background_img})` || 'transparent' }}
		>
			<div className={style.blockInner}>
				<div className={style.blockStart}>
					<div className={style.title}>{title}</div>
					<div className={style.divider} />
					<div className={style.detail}>
						<span dangerouslySetInnerHTML={{ __html: changeText(copy, 'trademarkSmallFont') }} />
					</div>
				</div>
				<div className={style.blockEnd}>
					{logo && <img src={logo} alt={title} data-brand={brand} className={style.logoImg} />}
					<Button
						variant={'img'}
						to={link_to}
						analytics={{
							category: pageName,
							action: `${title} button clicked`,
							label: `User is redirected to ${link_to}`
						}}
						className={style.button}
					>
						<img src={buttonImg} alt="" className={style.imgButton} />
					</Button>
				</div>
			</div>
		</motion.div>
	);
};
