import { useSelector } from 'react-redux';

function getPreviousIndex(currentProductIndex, length) {
	switch (length) {
		case 1:
			return false;
		default:
			return currentProductIndex === 0 ? length - 1 : currentProductIndex - 1;
	}
}

function getNextIndex(currentProductIndex, length) {
	switch (length) {
		case 1:
			return false;
		default:
			return currentProductIndex === length - 1 ? 0 : currentProductIndex + 1;
	}
}

const useWrappedSelector = ({ page }) => {
	const selector = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		const activePage = page;
		const data = state.content.getIn(['content', `products${language}`, activePage]).toJS();

		const locale = state.content.getIn(['content', 'market_details', 'country']).toLowerCase();

		const singleProduct = data.length === 1;

		// Active product is the one that is going to be shown big in the middle
		let activeProd = state.content.getIn(['activeProduct', activePage]).toJS();

		// This will be empty when we enter for the first time to the productDetail screen.
		// It will populate when you click on the colours
		const memoryForProducts = state.content.getIn(['historyOfProducts']);

		let activeColour = state.content.getIn(['activeProduct', activePage, 'colour']);
		let activeColourName = state.content.getIn(['activeProduct', activePage, 'colourName']);

		const currentProductData =
			data.find(({ shortName }) => shortName === activeProd.product) ?? data[0];

		let currentProductIndex = singleProduct
			? 0
			: data.findIndex(({ shortName }) => shortName === activeProd.product);

		currentProductIndex = currentProductIndex === -1 ? 0 : currentProductIndex;

		const currentProductName = data[currentProductIndex].shortName;

		const previousIndex = getPreviousIndex(currentProductIndex, data.length);
		const previousProductName =
			typeof previousIndex === 'number'
				? data[previousIndex].shortName
				: currentProductData.shortName;

		const nextIndex = getNextIndex(currentProductIndex, data.length);
		const nextProductName =
			typeof nextIndex === 'number' ? data[nextIndex].shortName : currentProductData.shortName;

		let previousProductImg = null;

		// If we do !previousIndex then in case the index is 0 will be = to false.
		// This is gets the image for the previous button
		if (typeof previousIndex === 'number') {
			previousProductImg =
				data[previousIndex].images.small_btn || data[previousIndex].images.default;
			const memorisedProduct = memoryForProducts.find(
				prod => prod.product === data[previousIndex].shortName
			);

			// This bit just checks if we previously selected a colour
			if (memorisedProduct) {
				const displayedColour = memorisedProduct.colourName;
				previousProductImg = data[previousIndex].images[displayedColour];
			}
		}

		let nextProductImg = null;
		if (typeof nextIndex === 'number') {
			nextProductImg = data[nextIndex].images.small_btn || data[nextIndex].images.default;
			const memorisedProduct = memoryForProducts.find(
				prod => prod.product === data[nextIndex].shortName
			);

			if (memorisedProduct) {
				const displayedColour = memorisedProduct.colourName;
				nextProductImg = data[nextIndex].images[displayedColour];
			}
		}

		// This controls the big image in the center, checks if we previously selected a colour and just applies it.
		if (memoryForProducts.length !== 0) {
			const memorisedProduct = memoryForProducts.find(prod => prod.product === activeProd.product);
			if (memorisedProduct) {
				activeColour = memorisedProduct.colour;
				activeColourName = memorisedProduct.colourName;
				activeProd = {
					product: currentProductData.shortName,
					colour: activeColour,
					colourName: activeColourName
				};
			}
		}

		return {
			activeProd,
			previousProductName,
			nextProductName,
			previousProductImg,
			nextProductImg,
			currentProductImg: currentProductData.images[activeColourName],
			currentProductColours: currentProductData.colours,
			currentProductData,
			currentProductName,
			activeColour,
			buttonClicked: state.content.getIn(['animation', 'productDetail']),
			page: activePage,
			colour_text: state.content.getIn(['content', `products${language}`, 'colour_text']),
			locale,
			copyrights: state.content.getIn(['content', 'copyrights'])?.toJS(),
			warnings: state.content.getIn(['content', `warnings${language}`])?.toJS()
		};
	});

	return selector;
};

export default useWrappedSelector;
