import React from 'react';
import PropTypes from 'prop-types';

import { Layout, useBackground } from 'bat-components';
import { Columns } from 'components/molecules';
import useWrappedSelector from './LearnCompare.selector';

export const Compare = () => {
	const { sectionsText, smush, images, locale, disclaimer } = useWrappedSelector();
	useBackground(images);

	return (
		<>
			<Layout>
				<Columns data={sectionsText} smush={smush} locale={locale} disclaimer={disclaimer} />
			</Layout>
		</>
	);
};

Compare.propTypes = {
	images: PropTypes.array,
	slideInterval: PropTypes.number,
	sectionsText: PropTypes.object,
	smallPrint: PropTypes.string,
	logos: PropTypes.array,
	links: PropTypes.array
};
