import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Button } from 'bat-components';
import { changeText } from 'helpers';

import whiteButtonImg from 'assets/images/shared/button.png';
import turqButtonImg from 'assets/images/shared/button_turq.png';
import veloWhiteBUTTONiMG from 'assets/images/shared/button_velo_white.png';
import { useDispatch } from 'react-redux';

import { productsAnimation, appearAnimation } from './animation';

import style from './products.module.scss';

export const Products = ({ productsToDisplay, className, page, locale }) => {
	const numberofProducts = productsToDisplay.length;

	const dispatch = useDispatch();

	function setActiveProduct(e, id, numberofProducts, typeOfProduct) {
		const product = {
			[typeOfProduct.toLowerCase()]: { product: id, colour: 'default', colourName: 'default' }
		};

		console.log(product);

		dispatch.content.selectActiveProduct(product);
		dispatch.content.setNumberOfProducts(numberofProducts);
		dispatch.content.cleanHistory();
		dispatch.content.setAnimationForProductDetail('firstTime');
	}

	const productsComponent = productsToDisplay.map((product, i) => {
		return (
			<motion.div
				className={clsx(
					style.productContainer,
					style[page],
					product.custom_brand_name && style[product.custom_brand_name.toLowerCase()]
				)}
				key={`${i}-product-container`}
				variants={appearAnimation}
			>
				<Button
					variants={{
						tap: {
							scale: 1.02
						}
					}}
					size={'small'}
					variant="img"
					to={product.coming_soon ? null : `/${page}/product`}
					analytics={{
						category: `${page} products`,
						action: `Button clicked`,
						label: `User clicks on ${product.title}`
					}}
					onClick={e => setActiveProduct(e, product.name, numberofProducts, page)}
				>
					<img
						src={product.image}
						alt={product.name}
						className={clsx(style.imgProduct, style[page])}
					/>

					<div className={style.name}>
						<span
							dangerouslySetInnerHTML={{
								__html: changeText(product.product_title, 'trademarkSmallFont')
							}}
						/>
					</div>

					{product.coming_soon ? (
						<span className={style.comingSoon}>{product.coming_soon}</span>
					) : (
						<img
							src={
								page === 'velo'
									? locale === 'uae_gtr'
										? veloWhiteBUTTONiMG
										: turqButtonImg
									: whiteButtonImg
							}
							alt="button"
							className={style.imgButton}
						/>
					)}
				</Button>
			</motion.div>
		);
	});

	return (
		<>
			<motion.div
				className={clsx(style[className], style.wrapper)}
				variants={productsAnimation}
				initial="from"
				animate="to"
				style={{ '--products-columns': numberofProducts }}
			>
				{productsComponent}
			</motion.div>
		</>
	);
};
