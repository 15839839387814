import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import QRCode from 'qrcode.react';
import { useBackground, useTheme, Layout, Button } from 'bat-components';

import { BackButton } from 'components/atoms';
import { TitleWithStrapline } from 'components/templates';

import style from './sign-up.module.scss';

export const SignUpScreen = () => {
	const [displayPage, setDisplayPage] = useState('Intro');

	const { intro, choose, scan, background, options } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			background:
				state.content.getIn(['content', `pages${language}`, 'sign_up', 'background']).toJS() ||
				null,
			intro: state.content.getIn(['content', `pages${language}`, 'sign_up', 'intro'])?.toJS(),
			choose: state.content.getIn(['content', `pages${language}`, 'sign_up', 'choose'])?.toJS(),
			scan: state.content.getIn(['content', `pages${language}`, 'sign_up', 'scan'])?.toJS(),
			options: state.content.getIn(['content', `pages${language}`, 'sign_up', 'options'])?.toJS()
		};
	});

	useBackground(background);
	useTheme('vuse');

	if (!intro || !choose) return <></>;

	return (
		<Layout page="How to use">
			{displayPage === 'Intro' ? (
				<Intro key={displayPage} intro={intro} setDisplayPage={setDisplayPage} />
			) : (
				<Qr choose={choose} scan={scan} options={options} />
			)}
		</Layout>
	);
};

const Intro = ({ intro, setDisplayPage }) => {
	return (
		<div className={style.introWrapper}>
			<span className={style.title}>{intro.title}</span>
			<span className={style.strapline}>{intro.strapline}</span>
			<div className={style.btnContainer}>
				{/* TODO: move it to bat components */}
				<BackButton
					variant="secondary"
					size="short"
					analytics={{
						category: 'Signup page',
						action: `No button clicked`,
						label: 'User is redirected to previous screen'
					}}
				>
					{intro.no}
				</BackButton>
				<Button
					variant="primary"
					size="short"
					onClick={() => setDisplayPage('Qr')}
					analytics={{
						category: 'Signup page',
						action: `Yes button clicked`,
						label: 'User is redirected to Signup to product screen'
					}}
				>
					{intro.yes}
				</Button>
			</div>
		</div>
	);
};

const Qr = ({ scan, choose, options }) => {
	const [qrPage, setQrPage] = useState({ data: choose, display: 'choose', brand: '' });

	if (!options || !choose) return <></>;

	const getComponent = page => {
		switch (page) {
			default:
			case 'choose':
				return Object.entries(options).map(([brandName, brandData]) => (
					<img
						src={brandData.image}
						alt=""
						key={brandName}
						className={style.item}
						onClick={() => setQrPage({ data: scan, display: 'scan', brand: brandName })}
					/>
				));

			case 'scan': {
				const date = new Date();
				const timestampToday = date.getTime();
				const encoded = window.btoa(unescape(encodeURIComponent(timestampToday)));

				return (
					<div className={clsx(style.item)}>
						<QRCode
							includeMargin={true}
							level="M"
							className={style.qrCode}
							size={312}
							value={`${options[qrPage.brand].qr_url}&signature=${encoded}`}
						/>
						<img className={style.logoQr} src={options[qrPage.brand].image} alt="" />
					</div>
				);
			}
		}
	};

	return (
		<TitleWithStrapline data={qrPage.data} page="qrCodes">
			<div className={clsx(style.itemsWrapper, style[qrPage.display || ''])}>
				{getComponent(qrPage.display)}
			</div>
		</TitleWithStrapline>
	);
};
