import React from 'react';
import { useBackground, Layout, useTheme, SplitButton } from 'bat-components';

import { ProductDetail } from 'components/molecules';

const transition = { duration: 0.6 };

const wrapperVariants = {
	from: () => ({
		opacity: 1,
		transition: { when: 'beforeChildren', delayChildren: 2 }
	}),
	to: {
		zIndex: 1,
		opacity: 1,
		transition: { when: 'beforeChildren', ...transition }
	},
	exit: () => ({
		zIndex: 0,
		opacity: 0,
		transition: { ...transition }
	})
};

export const IndividualProduct = ({
	background,
	productType,
	firstTimeAnimation,
	split_button
}) => {
	useTheme(productType);
	useBackground(background);

	const extraProps = firstTimeAnimation === 'firstTime' ? {} : { variants: wrapperVariants };

	return (
		<Layout {...extraProps}>
			<ProductDetail page={productType} />
			<SplitButton contents={split_button} position="bottom" />
		</Layout>
	);
};
