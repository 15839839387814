import { useSelector } from 'react-redux';

const useWrappedSelector = productType => {
	const selector = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		const displayedProducts = state.content
			.getIn(['content', `pages${language}`, `${productType}_products`, 'displayed_products'])
			?.toJS();

		const data = {
			info: state.content
				.getIn(['content', `pages${language}`, `${productType}_products`, 'sections'])
				.toJS(),
			products: displayedProducts,
			logo: state.content.getIn([
				'content',
				`pages${language}`,
				`${productType}_products`,
				'sections',
				'logo'
			])
		};
		const locale = state.content.getIn(['content', 'market_details', 'country']).toLowerCase();

		return {
			page: productType,
			data,
			background: state.content
				.getIn(['content', `pages${language}`, `${productType}_products`, 'background'])
				?.toJS(),
			locale
		};
	});

	return selector;
};

export default useWrappedSelector;
