import { connect } from 'react-redux';

import { QuizResult as Component } from './QuizResult';

const mapState = state => {
	const langState = state.content.getIn(['language']) || null;
	const language = langState && langState !== '' ? `_${langState}` : '';

	const results = state.content.getIn(['quiz', 'result']);

	const winner1 = results[0] ? results[0] : null;
	const winner2 = results[1];

	let winner1Info = [];

	if (winner1 && winner1 !== 'velo_pouch') {
		winner1Info = state.content
			.getIn(['content', `quiz${language}`, 'products'])
			.toJS()
			.filter(prod => {
				return prod.id === winner1;
			});
	}

	if (winner1 === 'velo_pouch') {
		winner1Info = state.content
			.getIn(['content', `quiz${language}`, 'products'])
			.toJS()
			.filter(prod => 'ePod' === prod.id);
	}

	const winner2Info = state.content
		.getIn(['content', `quiz${language}`, 'products'])
		.toJS()
		.filter(prod => prod.id === winner2);

	return {
		results: [winner1Info[0], winner2Info[0]],
		title: state.content.getIn(['content', `quiz${language}`, 'results', 'copy']),
		btn_text: state.content.getIn(['content', `quiz${language}`, 'results', 'btn_text']),
		background: state.content.getIn(['content', `quiz${language}`, 'background'])?.toJS(),

		getName: (productType, name) => {
			const product = state.content
				.getIn(['content', `products${language}`, productType])
				.toJS()
				.find(product => product.shortName === name);

			if (product.custom_brand_name) return product.custom_brand_name;

			return product.title;
		}
	};
};

export const QuizResult = connect(mapState)(Component);
