import { useSelector } from 'react-redux';

const useWrappedSelector = productType => {
	const selector = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		const data = state.content.getIn(['content', `products${language}`, productType])?.toJS();
		const activeProd = state.content.getIn(['activeProduct', productType])?.toJS();
		const currentProductData =
			data.find(({ shortName }) => shortName === activeProd.product) ?? data[0];

		return {
			split_button: currentProductData.split_buttons || null,
			background: state.content
				.getIn(['content', `pages${language}`, `${productType}_products`, 'background'])
				.toJS(),
			firstTimeAnimation: state.content.getIn(['animation', 'productDetail'])
		};
	});

	return selector;
};

export default useWrappedSelector;
