import { Map, List, fromJS } from 'immutable';
import { sendAnalytics } from 'helpers';

const initialString = 'No results';
const initialResultState = List([{ product: initialString }, { product: initialString }]);
export const content = {
	state: Map({
		animation: { productDetail: 'firstTime' },
		quiz: { products: null, result: initialResultState },
		historyOfProducts: [],
		activeProduct: Map({
			vype: Map({ product: 'ePen', colour: 'default', colourName: 'default' }),
			vuse: Map({ product: 'alto', colour: 'default', colourName: 'default' }),
			glo: Map({ product: 'hyper', colour: 'default', colourName: 'default' }),
			velo: Map({ product: 'velo', colour: 'default', colourName: 'default' })
		})
	}),
	reducers: {
		setInitialState(state, payload) {
			return state.setIn(['content'], fromJS(payload));
		},
		setActiveProduct(state, payload) {
			return state.setIn(['activeProduct', payload.product], fromJS(payload.values));
		},
		selectActiveProduct(state, payload) {
			const oldObject = state.getIn(['activeProduct']) ? state.getIn(['activeProduct']).toJS() : {};

			const object = { ...oldObject, ...payload };

			return state.setIn(['activeProduct'], fromJS(object));
		},
		cleanHistory(state) {
			return state.setIn(['historyOfProducts'], []);
		},
		pushHistoryProducts(state, payload) {
			const oldArray = state.getIn(['historyOfProducts']);
			const array = [...oldArray.filter(item => item.product !== payload.product), payload];

			return state.setIn(['historyOfProducts'], array);
		},
		setNumberOfProducts(state, payload) {
			return state.setIn(['numberOfProducts'], payload);
		},
		selectActiveColour(state, payload) {
			return state.setIn(['activeProduct', payload.product, 'colour'], payload.colour);
		},
		selectActiveColourName(state, payload) {
			return state.setIn(['activeProduct', payload.product, 'colourName'], payload.colourName);
		},
		setAnimationForProductDetail(state, payload) {
			return state.setIn(['animation', 'productDetail'], payload);
		},
		setProductsforQuiz(state) {
			const products = state.getIn(['content', 'quiz', 'products']).toJS();

			const type = products.map(product => {
				return { product: product.type, counter: 0 };
			});

			const reducedTypes = type.reduce((accumulator, element) => {
				if (!accumulator.find(el => el['product'] === element['product'])) {
					accumulator.push(element);
				}
				return accumulator;
			}, []);

			return state.setIn(['quiz', 'products'], reducedTypes);
		},
		setPointsforQuiz(state, payload) {
			const selectedIndex = state
				.getIn(['quiz', 'products'])
				.findIndex(elem => elem.product === payload);
			const currentValue = state.getIn(['quiz', 'products', selectedIndex, 'counter']);

			return state.setIn(['quiz', 'products', selectedIndex, 'counter'], currentValue + 1);
		},
		setLanguage(state, payload) {
			return state.setIn(['language'], payload).setIn(['content', 'language'], payload);
		},
		setBackgrounds(state, payload) {
			return state.setIn(['backgrounds'], payload);
		},

		getResultQuiz(state, option) {
			const products = state.getIn(['quiz', 'products']);
			const productsData = state.getIn(['content', 'quiz', 'products'])?.toJS();
			const priorities = state.getIn(['content', 'quiz', 'priority']);

			let productsOrderedByPriority = [];

			//Creates an array that contains just priority products
			priorities.forEach(priority => {
				const value = products.filter(item => item.product === priority);
				productsOrderedByPriority.push(value[0]);
			});

			//Returns the object with the highest value in the counter.
			const reducer = (accumulator, currentValue) =>
				currentValue.counter > accumulator.counter ? currentValue : accumulator;

			//Winner for first group of products
			const type_winner_1 = productsOrderedByPriority.reduce(reducer);

			let winner_1 = '';
			const winner_2 = 'velo_pouch';

			const getWinner = brand => {
				return productsData.map(product => {
					if (product.type === brand) {
						return product.id;
					}
					return '';
				});
			};

			winner_1 = getWinner(type_winner_1.product).filter(Boolean);


			//Winner from last group of products
			//Object - winners
			const winners = [winner_1[0], winner_2];

			//Send analytics
			sendAnalytics({
				category: 'Quiz Result',
				action: `${winner_2} - ${winner_1}`,
				label: 'Result was presented according to users preference'
			});

			return state.setIn(['quiz', 'result'], winners);
		},
		resetCounters(state) {
			const quizInfo = state.getIn(['quiz']);
			const resetCounter = quizInfo.products.map(product => {
				return { product: product.product, counter: 0 };
			});

			return state.setIn(['quiz', 'products'], resetCounter);
		}
	},
	effects: {}
};
