import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import engFlag from 'assets/flags/united_kingdom.svg';
import gerFlag from 'assets/flags/germany.svg';

import { sendAnalytics } from 'helpers';
import backIcon from 'assets/icons/back.svg';
import style from './navbar.module.scss';
import { useDispatch } from 'react-redux';

export const Navbar = ({ show, btn_back_text, history, links, langState, multilanguage, idle_background }) => {
	const liVariants = {
		enter: {
			y: '0%',
			transition: { duration: 0.5 }
		}
	};

	// -- Changes the nav animation entry direction based on locale
	const navVariants = {
		initial: {
			y: '-100%',
			opacity: 0
		},
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				when: 'beforeChildren',
				staggerChildren: 0.15,
				duration: 0.3
			}
		},
		exit: {
			y: '-100%'
		}
	};

	const flagURL = langState === 'german' ? engFlag : gerFlag;
	const dispatch = useDispatch();

	return (
		<>
			<AnimatePresence>
				{show && (
					<>
						<motion.nav
							className={style.nav}
							initial="initial"
							animate="enter"
							exit="exit"
							variants={navVariants}
							transition={{ duration: 0.5 }}
						>
							{btn_back_text && (
								<button
									className={style.back}
									onClick={() => {
										history.goBack();

										sendAnalytics({
											category: 'Navbar',
											action: `Back button clicked`,
											label: `User was taken to previous page in history`
										});
									}}
								>
									<div className={style.backWrapper}>
										<img className={style.logo} alt="" src={backIcon} />
										{btn_back_text}
									</div>
								</button>
							)}
							{links && (
								<ul className={style.links}>
									{links.map(link => {
										const analyticsLinks = {
											category: 'Navbar',
											action: `${link.title} option clicked`,
											label: `User is redirected to ${link.link}`
										};

										return (
											<motion.li
												className={style.item}
												key={link.link}
												initial={{ y: '100%' }}
												variants={liVariants}
											>
												<NavLink
													to={`${link.link}`}
													className={clsx(
														style.link,
														history.location.pathname === link.link && style.active
													)}
													onClick={e => {
														if (history.location.pathname === link.link) return e.preventDefault();
														sendAnalytics(analyticsLinks);
													}}
												>
													<span className={style.pretitle}>{link.prefix}</span>
													<span className={style.title}>{link.title}</span>
												</NavLink>
											</motion.li>
										);
									})}
									{multilanguage && (
										<motion.li className={style.item} initial={{ y: '100%' }} variants={liVariants}>
											<NavLink
												key="multilanguage"
												to={`/`}
												onClick={() => {
													dispatch.content.setBackgrounds(idle_background);
													setTimeout(() => {
														dispatch.content.setLanguage(langState === 'german' ? '' : 'german');
													}, 600);
												}}
											>
												<img className={style.linkImg} src={flagURL} alt="" />
											</NavLink>
										</motion.li>
									)}
								</ul>
							)}
						</motion.nav>

						<div className={style.offset}></div>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

Navbar.propTypes = {
	show: PropTypes.bool,
	btn_back_text: PropTypes.string,
	history: PropTypes.object,
	links: PropTypes.array,
	to_links: PropTypes.object
};
