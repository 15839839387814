import { useSelector } from 'react-redux';

const useWrappedSelector = () =>

	useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			disclaimer:
				state.content.getIn(['content', `pages${language}`, 'learn_compare', 'disclaimer']) ?? null,
			sectionsText: state.content.getIn([
				'content',
				`pages${language}`,
				'learn_compare',
				'sections'
			]),
			smush: state.content.getIn(['content', `pages${language}`, 'learn_compare', 'smush']),
			images: state.content
				.getIn(['content', `pages${language}`, 'learn_compare', 'background'])
				?.toJS(),
			displayed_intro_pages: state.content.getIn(['introDisplay']),
			locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase()
		};
	});

export default useWrappedSelector;
