import ReactGA from 'react-ga';

//Useful functions

const boldRegex = /[$]b_(.*?)(\s|\.|,|_|!|\?)/g;
const trademarkRegex = /[$]_(.*?)TM/g;

//Find trademark for glo and inject it in spans to get right styling
const changeTradeMark = (string, value, className = '') => {
	const result = trademarkRegex.exec(string);

	const word = result ? result[1] : 'glo';

	const newMarkup = `<span><strong>${word}</strong><sup class="${className}">TM</sup></span>`;

	return string.replace(value, newMarkup);
};

//Find bold characters
const changeBold = (string, value) => {
	const result = boldRegex.exec(string);

	let word = result ? result[1] : 'match';

	switch (word) {
		case 'vypeePod':
			word = 'Vype ePod';
			break;
		case 'vypeePen3':
			word = 'Vype ePen 3';
			break;
		case 'vype':
			word = 'Vype';
			break;
		default:
			break;
	}

	let nextCharacter = result[2] ? result[2] : ' ';
	nextCharacter = nextCharacter === '_' ? '' : nextCharacter;

	const newMarkup = `<strong>${word}${nextCharacter}</strong>`;

	return string.replace(value, newMarkup);
};

export const changeText = (string, className) => {
	if (!string) return;

	let tempString = string;
	const hasBold = string.match(boldRegex);
	const hasTrademark = string.match(trademarkRegex);

	if (hasBold) {
		hasBold.forEach(value => {
			tempString = changeBold(tempString, value);
		});
	}

	if (hasTrademark) {
		hasTrademark.forEach(value => {
			tempString = changeTradeMark(tempString, value, className);
		});
	}

	return tempString;
};

//Send analytics to google
export const sendAnalytics = analytics => {
	ReactGA.event(analytics);
};
